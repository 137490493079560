import React from 'react'

import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import TableBasePagination from './components/TableBasePagination'
import { RenderTableTitleTypes } from './components/TableTitleTypes'
import {
  StyledTable,
  StyledTableText,
  EmptyTableIconContainer,
  EmptyTableIcon,
  EmptyTableText,
  UnStyledLink
} from './TableStyles'
import EmptyTable from '../../../assets/icons/empty_table.svg'
import { EmptySpace } from '../../styles/BasicStyles'
import { InputError } from '../inputs/InputStyles'

const renderEmpty = (result) =>
  result !== null && result !== undefined && result !== '' ? (
    result
  ) : (
    <EmptySpace />
  )

const BaseTable = ({
  datasource,
  columns,
  rowSelection,
  onClickRow,
  sortCallback,
  pagination,
  PaginationComponent,
  FooterComponent,
  meta,
  rowKey,
  loading,
  emptyMessage,
  expandable,
  highlightRow,
  isWhiteTable,
  colorEx,
  scroll,
  linkAddress
}) => {
  const showError = meta.invalid && meta.submitFailed
  const columnsWrapper = (source) => {
    if (Array.isArray(source) && source.length > 0) {
      const newSource = source.map((current, index) => {
        const isLastElement = index === source.length - 1
        const result = {
          ...current,
          showSorterTooltip: false,
          title: ({ sortOrder, sortColumn }) =>
            RenderTableTitleTypes(
              current.type,
              current.title,
              {
                sortOrder,
                sortColumn,
                input: current.input,
                filters: current.filters,
                filtersKey: current.filtersKey,
                filtersLabel: current.filtersLabel,
                dataIndex: current.dataIndex,
                click: current.click,
                clickOrder: current.clickOrder
              },
              isLastElement
            ),
          render: (text, row, renderIndex) =>
            linkAddress ? (
              <UnStyledLink to={linkAddress(row)}>
                <StyledTableText
                  $noBorder={isLastElement}
                  $align={current.align}
                  onClick={
                    onClickRow ? (event) => onClickRow(row, event) : null
                  }
                  $linkAddress
                >
                  {current.render
                    ? renderEmpty(current.render(text, row, renderIndex))
                    : renderEmpty(text)
                  }

                </StyledTableText>
              </UnStyledLink>

            ) : (
              <StyledTableText
                $noBorder={isLastElement}
                $align={current.align}
                onClick={
                  onClickRow ? (event) => onClickRow(row, event) : null
                }
              >
                {current.render
                  ? renderEmpty(current.render(text, row, renderIndex))
                  : renderEmpty(text)
                }

              </StyledTableText>
            )

        }

        return result
      })

      return newSource
    }
    return []
  }

  const locale = {
    emptyText: (
      <EmptyTableIconContainer>
        <EmptyTableText>
          {emptyMessage || <Translate id='TABLE_IS_EMPTY' />}
        </EmptyTableText>
        <EmptyTableIcon src={EmptyTable} />
      </EmptyTableIconContainer>
    )
  }

  let SelectionObject

  if (rowSelection) {
    const onSelectRow = (record, isSelected) => {
      const list = [...rowSelection.selected]
      if (isSelected) {
        list.push({ key: record[rowKey], record })
        rowSelection.onRowSelection(list)
      } else {
        rowSelection.onRowSelection(
          list.filter((elem) => elem.key !== record[rowKey])
        )
      }
    }

    const onSelectRowAll = (isSelected, selectedRows) => {
      if (isSelected) {
        rowSelection.onRowSelection(
          selectedRows
            .map((record) =>
              record ? { key: record[rowKey], record } : undefined
            )
            .filter((a) => a)
        )
      } else rowSelection.onRowSelection([])
    }

    SelectionObject = {
      ...rowSelection,
      fixed: true,
      columnWidth: '30px',
      selectedRowKeys: rowSelection?.selected?.key,
      onSelect: onSelectRow,
      preserveSelectedRowKeys: false,
      onSelectAll: onSelectRowAll
    }
  }

  const setHighlightRow = (record) => {
    if (highlightRow && typeof highlightRow === 'function') {
      return highlightRow(record) ? 'notSeenRow' : ''
    }
    return ''
  }

  let onChange
  if (typeof sortCallback === 'function') {
    onChange = (page, filters, sort) => sortCallback(sort)
  }

  return (
    <>
      <StyledTable
        rowSelection={SelectionObject}
        dataSource={datasource}
        onChange={onChange}
        columns={columnsWrapper(columns)}
        showSorterTooltip={false}
        rowKey={rowKey}
        pagination={false}
        $hasError={showError}
        loading={loading}
        scroll={scroll}
        footer={
          FooterComponent
            ? (currentPageData) => (
              <FooterComponent currentPageData={currentPageData} />
            )
            : undefined
        }
        locale={locale}
        $isWhiteTable={isWhiteTable}
        expandable={expandable}
        rowClassName={setHighlightRow}
        color={colorEx}
      />
      {showError && (
        <InputError>
          {' '}
          <Translate id={meta.error} />
        </InputError>
      )}
      {pagination.render && (
        <PaginationComponent
          page={pagination.page}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChangePagination={pagination.onChangePagination}
        />
      )}
    </>
  )
}

BaseTable.propTypes = {
  datasource: PropTypes.array,
  columns: PropTypes.array.isRequired,
  sortCallback: PropTypes.func,
  rowSelection: PropTypes.object,
  onClickRow: PropTypes.func,
  linkAddress: PropTypes.func,
  pagination: PropTypes.object,
  PaginationComponent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]),
  FooterComponent: PropTypes.node,
  loading: PropTypes.bool,
  meta: PropTypes.object,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  emptyMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  expandable: PropTypes.object,
  highlightRow: PropTypes.func,
  isWhiteTable: PropTypes.bool,
  colorEx: PropTypes.string,
  scroll: PropTypes.object
}

BaseTable.defaultProps = {
  datasource: [],
  rowSelection: undefined,
  onClickRow: null,
  sortCallback: undefined,
  pagination: {
    render: true,
    page: 1,
    pageSize: 30,
    total: 0,
    onChangePagination: () => null
  },
  PaginationComponent: TableBasePagination,
  FooterComponent: undefined,
  loading: false,
  meta: {},
  rowKey: 'key',
  emptyMessage: undefined,
  expandable: undefined,
  highlightRow: undefined,
  isWhiteTable: false,
  colorEx: undefined,
  scroll: undefined,
  linkAddress: undefined
}

export default BaseTable
