import React, { useEffect, useState } from "react";

import {
  CloseCircleOutlined,
  DownloadOutlined,
  FormOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { reduxForm, FieldArray, initialize, getFormValues } from "redux-form";

import Export from "../../../assets/icons/export_white.svg";
import {
  CreatInternalPO,
  GetOrderB2BDetails,
  UpdateOrderB2B,
  AppendOrderFiles,
  DeleteOrderAttachment,
} from "../../../infra/requests/OrdersB2BRequests";
import FormValidator from "../../../infra/services/validations/FormValidator";
import AlertService from "../../../shared/components/alert/AlertService";
import BackButton from "../../../shared/components/buttons/BackButton";
import BaseButton from "../../../shared/components/buttons/BaseButton";
import InternalPOTable from "../../../shared/components/inputs/Products/InternalPOTable";
import OrderSupplierTable from "../../../shared/components/inputs/Products/OrderSupplierTable";
import ProductsTable from "../../../shared/components/inputs/Products/ProductsTable";
import BaseLoading from "../../../shared/components/loading/BaseLoading";
import {
  PageTitle,
  PageForm,
  Margin,
  StyledTabs,
  StyledTabPane,
  StyledTabTitleContainer,
  StyledTabTitle,
} from "../../../shared/styles/BasicStyles";
import EditableOrderHeader from "./components/EditableOrderHeader";
import OrderProductsTable from "./components/OrderProductsTable";

const RefactorDetail = (data) => ({
  ...data,
  b2bclientId: data?.b2bclient?.b2bclientId,
  currency: {
    ...data?.currency,
    currencyId: data?.currencyId,
    exchangeRate: data?.exchangeRateUsed,
  },
  orderB2bitem: data.orderB2bitem.map((p) => ({
    ...p,
    reference: p?.productCombination?.reference,
    barcode: p?.productCombination?.barcode,
    description: p?.productCombination?.product?.productTranslation,
    color: p?.productCombination?.color,
    size: p?.productCombination?.size,
    productWidth: p?.productCombination?.productWidth,
    totalValue: p.unitPrice,
  })),
});

const validations = FormValidator.make({
  client: "required",
  payment_condition: "required",
  delivery_location: "required",
  shipping_condition: "required",
  delivery_date: "required",
  products: "required|array",
});

const ManageOrderB2B = ({
  router,
  handleSubmit,
  dispatch,
  formValues,
  translate,
}) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [loadPO, setLoadPO] = useState(false);
  const [backup, setBackup] = useState({});
  const [isNew, setIfNew] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    async function fetchOrder() {
      const { data, success } = await GetOrderB2BDetails(id);
      if (success) {
        setIfNew(data.orderStatusId === 0);
        const refactor = RefactorDetail(data);
        setBackup(JSON.parse(JSON.stringify(refactor)));
        dispatch(initialize("edit_order_b2b", refactor));
      }
      setLoading(false);
    }
    fetchOrder();
  }, []);

  const shouldUpdateAttachments = async (values) => {
    if (values.attachment && values.attachment.length) {
      let newFiles = false;
      const formData = new FormData();
      const types = [];
      values.attachment.forEach((attach) => {
        if (!attach.attachmentId) {
          newFiles = true;
          formData.append("Attachments", attach.file);
          types.push(attach.type);
        }
      });
      if (newFiles) {
        formData.append("AttachmentTypes", JSON.stringify(types));
        await AppendOrderFiles(id, formData);
      }
    }
  };

  const onSubmit = async (values) => {
    try {
      setSaving(true);
      const payload = {
        b2bclientId: values.b2bclientId,
        stockOwnerId: values.stockOwnerId,
        dateDelivery: values.dateDelivery,
        deliveryContactDetailId: values.deliveryContactDetailId,
        orderB2bitem: values.orderB2bitem,
        paymentConditionId: values.paymentConditionId,
        shippingConditionId: values.shippingConditionId,
        currencyId: values.currencyId,
      };
      await shouldUpdateAttachments(values);
      const { success } = await UpdateOrderB2B(id, payload);

      if (success) {
        setBackup(JSON.parse(JSON.stringify(values)));
        dispatch(initialize("edit_order_b2b", values));
        setEditing(false);
        AlertService.success(
          translate("ORDER_SAVED"),
          translate("CHANGES_SAVED_SUCCESSFULLY")
        );
      }
      setSaving(false);
    } catch (error) {
      console.warn(error);
      setSaving(false);
    }
  };

  const handleFormReset = () => {
    dispatch(initialize("edit_order_b2b", backup));
    setEditing(false);
  };

  const handleDocumentDelete = async (attachmentId) =>
    DeleteOrderAttachment(attachmentId);

  const createInternalPO = async () => {
    setLoadPO(true);
    const { success } = await CreatInternalPO([formValues.orderB2bid]);
    if (success) router.history.push("/suppliers#open");
  };

  if (loading) return <BaseLoading />;

  return (
    <PageForm onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id="BACK_ORDERS" />}
            onClick={() => router.history.push("/orders#b2b")} π
          />
        </Col>

        <Col sm={24} md={10} lg={8}>
          <PageTitle>
            <Translate id="ORDER_NMR" />{" "}
            {formValues.number || formValues.orderB2bid}
          </PageTitle>
        </Col>
        <Col sm={24} md={14} lg={16}>
          <div style={{ textAlign: "right" }}>
            {formValues.orderStatusId === 0 && (
              <BaseButton
                loading={loadPO}
                style={{ marginRight: "20px" }}
                onClick={createInternalPO}
                type="primary"
                imagePath={Export}
                auto
              >
                <Translate id="CREATE_INTERNAL_PO" />
              </BaseButton>
            )}

            {/* <BaseButton
              onClick={() => console.warn('download')}
              type='secondary'
              icon={<DownloadOutlined />}
              auto
            >
              <Translate id='DOWNLOAD' />
            </BaseButton> */}

            {editing && (
              <BaseButton
                loading={loading}
                onClick={handleFormReset}
                type="secondary"
                style={{ marginLeft: "20px" }}
                auto
                icon={<CloseCircleOutlined />}
              >
                <Translate id="CANCEL" />
              </BaseButton>
            )}

            {editing ? (
              <BaseButton
                loading={saving}
                onClick={handleSubmit(onSubmit)}
                type="primary"
                style={{ marginLeft: "20px" }}
                auto
                icon={<SaveOutlined />}
              >
                <Translate id="SAVE" />
              </BaseButton>
            ) : (
              <BaseButton
                loading={loading}
                onClick={() => setEditing(true)}
                type="secondary"
                style={{ marginLeft: "20px" }}
                auto
                icon={<FormOutlined />}
              >
                <Translate id="EDIT_ORDER" />
              </BaseButton>
            )}
          </div>
        </Col>
      </Row>
      <Margin size="30" />
      <EditableOrderHeader
        order={formValues}
        editing={editing}
        deleteDocument={handleDocumentDelete}
      />
      <Margin size="50" />
      <StyledTabs>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle>
                <Translate id="ORDER_ITEMS" />
              </StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key={1}
        >
          {editing && isNew ? (
            <FieldArray
              component={ProductsTable}
              name="orderB2bitem"
              client={formValues?.b2bclientId}
              currency={formValues?.currency}
            />
          ) : (
            <FieldArray
              component={OrderProductsTable}
              name="orderB2bitem"
              currency={formValues?.currency}
            />
          )}
        </StyledTabPane>
        {!isNew && (
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id="INTERNAL_PO" />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={2}
          >
            <InternalPOTable order={id} currency={formValues?.currency} />
          </StyledTabPane>
        )}
        {!isNew && (
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id="SUPPLIER_ORDERS" />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={3}
          >
            <OrderSupplierTable order={id} currency={formValues?.currency} />
          </StyledTabPane>
        )}
      </StyledTabs>
    </PageForm>
  );
};

ManageOrderB2B.propTypes = {
  router: PropTypes.object.isRequired,
  formValues: PropTypes.object,
};

ManageOrderB2B.defaultProps = {
  formValues: {},
};

const ManageOrderB2BPage = reduxForm({
  form: "edit_order_b2b",
  validate: validations,
})(ManageOrderB2B);

export default withLocalize(
  connect((state) => ({
    formValues: getFormValues("edit_order_b2b")(state),
    orderStatus: state.info.orderStatus,
    paymentConditions: state.info.paymentConditions,
    deliveryLocations: state.info.deliveryLocations,
    shippingConditions: state.info.shippingConditions,
    clients: state.info.clients,
  }))(ManageOrderB2BPage)
);
