import React, { useState } from 'react'

import { Checkbox, Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Field,
  reduxForm,
  initialize,
  getFormValues,
  change
} from 'redux-form'
import styled from 'styled-components'

import { GetAllWarehouses } from '../../../../infra/requests/LogisticsRequests'
import {
  GetProductCombinations,
  CancelOrderItems,
  EditOrderItems
} from '../../../../infra/requests/OrderItemsRequests'
import { StartReturnExchange } from '../../../../infra/requests/OrdersB2CRequests'
import FormValidator from '../../../../infra/services/validations/FormValidator'
import AlertService from '../../../../shared/components/alert/AlertService'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import {
  InputBox,
  InputLabel
} from '../../../../shared/components/inputs/InputStyles'
import IntegerInput from '../../../../shared/components/inputs/IntegerInput'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TextAreaInput from '../../../../shared/components/inputs/TextAreaInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import BaseModal from '../../../../shared/components/modal/BaseModal'
import BaseTable from '../../../../shared/components/table/BaseTable'
import { ArrayPushIfNotExist } from '../../../../shared/logic/arrays/ArrayFunctions'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import { PageForm } from '../../../../shared/styles/BasicStyles'

let timeout

const validations = (values) => {
  const validator = FormValidator.make({
    orderItemId: 'required',
    barcode: 'required',
    reference: 'required',
    colorId: 'required',
    productWidthId: 'required',
    sizeId: 'required',
    statusId: 'required'
  })

  // Check if statusId is 1 or 3 (processing or confirmed) if so, make warehouseName required
  if (((values.statusId === '1' || values.statusId === 1) || (values.statusId === '3' || values.statusId === 3)) && values.warehouseName === '') {
    validator.warehouseName = 'required'
  }

  return validator
}

const NameDiv = styled.div`
  background: #f5f5f5;
  border: 1px solid #c6c8ca;
  border-radius: 4px;
  padding: 5px 10px;
  display: inline-block;
  width: 100%;
  height: 36px;
`

const getProductOptions = (combinations) => {
  const sizeList = []
  const colorList = []
  const widthList = []
  if (combinations && combinations.length) {
    combinations.forEach((comb) => {
      ArrayPushIfNotExist(
        sizeList,
        comb.size,
        (x) => x.sizeId === comb.size.sizeId
      )
      ArrayPushIfNotExist(
        colorList,
        comb.color,
        (x) => x.colorId === comb.color.colorId
      )
      ArrayPushIfNotExist(
        widthList,
        comb.productWidth,
        (x) => x.productWidthId === comb.productWidth.productWidthId
      )
    })
  }
  return { colorList, sizeList, widthList }
}

const findInCombination = (currentFormValues, combination, field, value) => {
  if (field === 'sizeId') {
    return (
      combination.sizeId == value &&
      (combination.colorId === currentFormValues.colorId ||
        !currentFormValues.colorId) &&
      (combination.productWidthId == currentFormValues.productWidthId ||
        !currentFormValues.productWidthId)
    )
  }
  if (field === 'colorId') {
    return (
      combination.colorId == value &&
      (combination.sizeId === currentFormValues.sizeId ||
        !currentFormValues.sizeId) &&
      (combination.productWidthId == currentFormValues.productWidthId ||
        !currentFormValues.productWidthId)
    )
  }
  if (field === 'productWidthId') {
    return (
      combination.productWidthId == value &&
      (combination.colorId === currentFormValues.colorId ||
        !currentFormValues.colorId) &&
      (combination.sizeId == currentFormValues.sizeId ||
        !currentFormValues.sizeId)
    )
  }
  if (field === 'reference') {
    return combination.reference == value
  }
  if (field === 'barcode') {
    return combination.barcode == value
  }
  return false
}

const NameInput = ({ input, label }) => (
  <InputBox>
    {label && <InputLabel>{label}</InputLabel>}
    <NameDiv>
      <ActiveTranslation value={input?.value} />
    </NameDiv>
  </InputBox>
)

NameInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string
}

NameInput.defaultProps = {
  input: undefined,
  label: ''
}

const ChangeProduct = ({
  open,
  products,
  handleClose,
  reset,
  dispatch,
  currentFormValues,
  orderStatus,
  handleSubmit,
  translate,
  isB2C
}) => {
  const [loading, setLoading] = useState(false)
  const [refundLoading, setRefundLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState([])
  const [combinations, setCombinations] = useState([])
  const [colors, setColors] = useState([])
  const [sizes, setSizes] = useState([])
  const [widths, setWidths] = useState([])
  const [product, setProduct] = useState()
  const [warehouses, setWarehouses] = useState([])

  const { id } = useParams()

  const canRefundArticles = products.some((p) => p.status.orderStatusId > 0 && p.status.orderStatusId < 6)

  const refundArticleCopy = selectedProduct.length > 1 ? 'REFUND_ARTICLES' : 'REFUND_ARTICLE'

  const handleWarehouseChange = (value) => {
    // Trigger validation when warehouseName changes
    dispatch(change('change-product-modal', 'warehouseId', value))
  }

  const fetchWarehouses = async () => {
    const response = await GetAllWarehouses()
    setWarehouses(response.data.items)
  }

  const onSelectProduct = async () => {
    if (selectedProduct) {
      setLoading(true)
      setProduct(selectedProduct[0])
      setSelectedProduct([])

      const { data, success } = await GetProductCombinations(
        selectedProduct[0]?.productCombination?.productId
      )

      if (success) {
        setCombinations(data || [])
        fetchWarehouses()

        const { colorList, sizeList, widthList } = getProductOptions(data)
        setColors(colorList)
        setSizes(sizeList)
        setWidths(widthList)
        dispatch(
          initialize('change-product-modal', {
            orderItemId: selectedProduct.orderItemId,
            name: selectedProduct?.productCombination?.product
              ?.productTranslation,
            barcode: selectedProduct?.productCombination?.barcode,
            reference: selectedProduct?.productCombination?.reference,
            colorId: selectedProduct?.productCombination?.colorId,
            productWidthId: selectedProduct?.productCombination?.productWidthId,
            sizeId: selectedProduct?.productCombination?.sizeId,
            quantity: selectedProduct.quantity,
            quantityConfirmed: selectedProduct.quantityConfirmed,
            quantityDelivered: selectedProduct.quantityDelivered,
            statusId: selectedProduct.status?.orderStatusId,
            warehouseId: selectedProduct.warehouseId,
            warehouseName: selectedProduct.warehouseName
          })
        )
      } else {
        setProduct(undefined)
        setSelectedProduct([])
      }
      setLoading(false)
    }
  }

  const handleDropdownChange = (field, value) => {
    const found = combinations.find((comb) =>
      findInCombination(currentFormValues, comb, field, value)
    )

    dispatch(change('change-product-modal', 'sizeId', found?.sizeId || ''))
    dispatch(change('change-product-modal', 'colorId', found?.colorId || ''))
    dispatch(
      change(
        'change-product-modal',
        'productWidthId',
        found?.productWidthId || ''
      )
    )
    dispatch(
      change('change-product-modal', 'reference', found?.reference || '')
    )
    dispatch(change('change-product-modal', 'barcode', found?.barcode || ''))
  }

  const handleInputChange = (field, value) => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      handleDropdownChange(field, value)
      dispatch(change('change-product-modal', field, value))
    }, 500)
  }

  const resetForm = () => {
    reset()
    setProduct()
    setSelectedProduct([])
  }

  const handleConfirmRefund = async () => {
    setRefundLoading(true)
    const refundItemList = selectedProduct.map((item) => ({ itemId: item.orderItemId }))
    const invalidItems = selectedProduct
      .filter((item) => item.status.orderStatusId === 0 || item.status.orderStatusId > 6)
      .map((item) => item.productCombination.reference)

    const payload = { items: refundItemList }

    if (invalidItems.length) {
      setRefundLoading(false)
      const invalidItemsReferences = invalidItems.join(', ')
      return AlertService.error(translate('ERROR'), translate('ITEMS_CANNOT_BE_REFUNDED', { invalidItemsReferences }))
    }

    try {
      const { success } = await StartReturnExchange(id, payload)
      if (success) {
        setSelectedProduct([])
        AlertService.success(translate('SUCCESS'), translate('REFUND_INITIATED'))
        handleClose(true)
      }
    } catch (error) {
      console.error(error)
    }
    setRefundLoading(false)
  }

  const handleRefundArticleClick = () => {
    AlertService.confirm(
      translate(refundArticleCopy),
      translate(selectedProduct.length > 1 ? 'REFUND_ARTICLES_CONFIRMATION' : 'REFUND_ARTICLE_CONFIRMATION'),
      handleConfirmRefund)
  }

  const onSubmit = async (values) => {
    setSaving(true)
    if (values.statusId === 6) {
      const { success } = await CancelOrderItems(values)
      if (success) {
        resetForm()
        handleClose(true)
      }
    } else {
      const payload = {
        orderItemId: values.orderItemId,
        quantity: values.quantity,
        quantityConfirmed: values.quantityConfirmed,
        sizeId: values.sizeId,
        colorId: values.colorId,
        quantityDelivered: values.quantityDelivered,
        barcode: values.barcode,
        statusId: values.statusId,
        warehouseid: parseInt(values.warehouseId, 10),
        comment: values.comment
      }

      const { success } = await EditOrderItems(payload)
      if (success) {
        resetForm()
        handleClose(true)
      }
    }
    setSaving(false)
  }

  const columns = [
    {
      type: 'text',
      title: <Translate id='REF_SKYPRO' />,
      dataIndex: 'productCombination',
      render: (value) => value?.reference || 'N/A'
    },
    {
      type: 'text',
      title: <Translate id='EAN_CODE' />,
      dataIndex: 'productCombination',
      render: (value) => value?.barcode || 'N/A'
    },
    {
      type: 'text',
      title: <Translate id='DESCRIPTION' />,
      dataIndex: 'productCombination',
      render: (value) => (
        <ActiveTranslation
          value={value?.product?.productTranslation || 'N/A'}
          tag='name'
        />
      )
    },
    {
      type: 'text',
      title: <Translate id='SIZE' />,
      dataIndex: 'productCombination',
      render: (value) => value?.size?.nameEu
    },
    {
      type: 'text',
      title: <Translate id='COLOR' />,
      dataIndex: 'productCombination',
      render: (value) => (
        <ActiveTranslation value={value?.color?.colorTranslation} tag='name' />
      )
    },
    {
      type: 'text',
      title: <Translate id='WIDTH' />,
      dataIndex: 'productCombination',
      render: (value) => value?.productWidth?.code || 'N/A'
    },
    {
      type: 'text',
      dataIndex: 'productCombination',
      title: <Translate id='STOCK' />,
      render: (value) => value?.stock
    },
    {
      type: 'text',
      dataIndex: 'quantity',
      title: <Translate id='QTY' />
    },
    {
      type: 'text',
      dataIndex: 'quantityConfirmed',
      title: <Translate id='QTY_CONFIRMED' />
    },
    {
      type: 'text',
      dataIndex: 'quantityDelivered',
      title: <Translate id='QTY_DELIVERED' />
    },
    {
      type: 'text',
      dataIndex: 'tracking',
      title: <Translate id='TRACKING_NUMBER' />
    },
    {
      type: 'text',
      dataIndex: 'status',
      title: <Translate id='STATUS' />,
      render: (value) =>
        value ? (
          <ActiveTranslation value={value?.orderStatusTranslation} tag='name' />
        ) : (
          'N/A'
        )
    },
    {
      type: 'text',
      dataIndex: 'warehouseName',
      title: <Translate id='WAREHOUSE_NAME' />
    },
    {
      align: 'right',
      className: 'text-success',
      type: 'text',
      dataIndex: 'unitTotalValueFinal',
      title: <Translate id='PRICE_UN' />,
      render: (value) => `${parseFloat(value).toFixed(2)}€`

    },
    {
      align: 'right',
      type: 'text',
      title: <Translate id='TOTAL' />,
      render: (data) =>
        `${parseFloat(data.quantity * data.unitTotalValueFinal).toFixed(2)}€`
    }
  ]

  const handleSelectProduct = (record) => {
    setSelectedProduct((prevState) =>
      prevState.some((p) => p.orderItemId === record.orderItemId)
        ? prevState.filter((p) => p.orderItemId !== record.orderItemId)
        : [...prevState, record]
    )
  }

  const handleSelectAll = (items) => {
    const selectedProducts = items.map((item) => item.record)

    setSelectedProduct(selectedProducts)
  }

  const renderContent = () => {
    if (loading) return <BaseLoading margin={100} />
    if (product) {
      return (
        <PageForm>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Field
                name='name'
                component={NameInput}
                label={<Translate id='PRODUCT' />}
              />
            </Col>
            <Col xs={6}>
              <Field
                name='reference'
                component={TextInput}
                label={<Translate id='REFERENCE' />}
                afterChange={(v) => handleInputChange('reference', v)}
              />
            </Col>
            <Col xs={6}>
              <Field
                name='barcode'
                component={TextInput}
                label={<Translate id='BARCODE' />}
                afterChange={(v) => handleInputChange('barcode', v)}
              />
            </Col>
            <Col xs={4}>
              <Field
                name='sizeId'
                component={SelectInput}
                label={<Translate id='SIZE' />}
                data={sizes}
                dataKey='sizeId'
                dataLabel='number'
                afterChange={(v) => handleDropdownChange('sizeId', v)}
                allowClear={false}
              />
            </Col>
            <Col xs={4}>
              <Field
                name='colorId'
                component={SelectInput}
                label={<Translate id='COLOR' />}
                data={colors}
                dataKey='colorId'
                dataLabel='colorTranslation'
                afterChange={(v) => handleDropdownChange('colorId', v)}
                allowClear={false}
              />
            </Col>
            <Col xs={4}>
              <Field
                name='productWidthId'
                component={SelectInput}
                label={<Translate id='WIDTH' />}
                data={widths}
                dataKey='productWidthId'
                dataLabel='productWidthTranslation'
                afterChange={(v) => handleDropdownChange('productWidthId', v)}
                allowClear={false}
              />
            </Col>
            <Col xs={8}>
              <Field
                name='quantity'
                component={IntegerInput}
                label={<Translate id='QUANTITY' />}
              />
            </Col>
            <Col xs={8}>
              <Field
                name='quantityConfirmed'
                component={IntegerInput}
                label={<Translate id='QUANTITY_CONFIRMED' />}
              />
            </Col>
            <Col xs={8}>
              <Field
                name='quantityDelivered'
                component={IntegerInput}
                label={<Translate id='QUANTITY_DELIVERED' />}
              />
            </Col>
            <Col xs={6}>
              <Field
                name='statusId'
                component={SelectInput}
                label={<Translate id='STATUS' />}
                data={orderStatus.filter(
                  (x) => x.orderStatusId > 0 && x.orderStatusId < 7
                )}
                dataKey='orderStatusId'
                dataLabel='orderStatusTranslation'
                allowClear={false}
              />
            </Col>
            <Col xs={6}>

              <Field
                name='warehouseName'
                component={SelectInput}
                label={<Translate id='WAREHOUSE' />}
                // afterChange={(v) => handleDropdownChange("warehouseId", v)}
                afterChange={(v) => handleWarehouseChange(v)}
                data={warehouses}
                dataKey='warehouseId'
                dataLabel='name'
                allowClear={false} // This allows the field to be cleared, if it's set to true by default, you can remove it.
              />

            </Col>
            {currentFormValues?.statusId === '6' && (
              <Col xs={12}>
                <Field
                  name='comment'
                  component={TextAreaInput}
                  rows={3}
                  label={<Translate id='COMMENT' />}
                />
              </Col>
            )}
            <Col xs={24} style={{ textAlign: 'right', marginTop: '10px' }}>
              <BaseButton
                auto
                type='secondary'
                onClick={resetForm}
                style={{ marginRight: '15px' }}
              >
                <Translate id='BACK' />
              </BaseButton>
              <BaseButton
                style={{ marginRight: '10px' }}
                auto
                onClick={handleSubmit(onSubmit)}
                loading={saving}
              >
                <Translate id='SAVE' />
              </BaseButton>
            </Col>
          </Row>
        </PageForm>
      )
    }
    return (
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <BaseTable
            rowKey='orderItemId'
            datasource={products}
            columns={columns}
            pagination={{ render: false }}
            scroll={{ x: true }}
            rowSelection={{
              hideSelectAll: !isB2C || !canRefundArticles,
              selected: selectedProduct,
              onRowSelection: (items) => {
                handleSelectAll(items)
              },
              renderCell: (prop, record) => (
                <Checkbox
                  checked={selectedProduct.find((p) => p.orderItemId === record.orderItemId)}
                  onChange={() => (isB2C && canRefundArticles) ? handleSelectProduct(record) : setSelectedProduct([record])}
                />
              )
            }}
          />
        </Col>
        <Col xs={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {isB2C && canRefundArticles && (
            <BaseButton type='error' auto onClick={handleRefundArticleClick} disabled={selectedProduct.length === 0} loading={refundLoading}>
              <Translate id={refundArticleCopy} />
            </BaseButton>
          )}
          <BaseButton
            auto
            disabled={selectedProduct.length === 0 || selectedProduct.length > 1 || refundLoading}
            onClick={onSelectProduct}
          >
            <Translate id='EDIT' />
          </BaseButton>
        </Col>
      </Row>
    )
  }

  return (
    <BaseModal
      title={<Translate id='CHANGE_PRODUCTS' />}
      open={open}
      onClose={() => handleClose()}
      width='90%'
    >
      {renderContent()}
    </BaseModal>
  )
}

ChangeProduct.propTypes = {
  open: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  currentFormValues: PropTypes.object,
  orderStatus: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  isB2C: PropTypes.bool
}

ChangeProduct.defaultProps = {
  currentFormValues: undefined,
  isB2C: false
}

const ChangeProductModal = reduxForm({
  form: 'change-product-modal',
  validate: validations
})(ChangeProduct)

export default withLocalize(connect((state) => ({
  orderStatus: state.info.orderStatus,
  currentFormValues: getFormValues('change-product-modal')(state)
}))(ChangeProductModal))
